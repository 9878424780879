import * as React from "react"

const BigCards = (props) => {
  return (
    <section className="section home-feature">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                      {props.cards.map((item, i) => {
                        const svg = (item.icon)
                        return (
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4"  key={`big-cards-${i}`} data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                              <div className="features-small-item h-100">
                                  <div className="icon text-white" dangerouslySetInnerHTML={{ __html: svg }}>
                                  </div>
                                  <h5 className="features-title">{item.title}</h5>
                                  <p>{item.description}</p>
                              </div>
                          </div>
                        )
                      })}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BigCards
