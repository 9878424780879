import * as React from "react"
import { Link } from "gatsby"

const Pricing = (props) => {
  const view = props.view
  return (
      <section className="section colored" id="pricing">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="center-heading">
                          <h2 className="section-title">{view.title}</h2>
                      </div>
                  </div>
                  <div className="offset-lg-2 col-lg-8">
                      <div className="center-text">
                          <p>{view.description}</p>
                          <i>{view.extra}</i>
                      </div>
                  </div>
              </div>

              <div className="row justify-content-center">
                  {view.plans.map((plan, i) => {
                      return (
                        <div className="col" key={`pricing-${i}`} data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                            <div className="pricing-item h-100">
                                <div className="pricing-header">
                                </div>
                                <div className="pricing-body h-75">
                                    <div className="price-wrapper background-gradient">
                                        <span className="currency">$</span>
                                        <span className="price">{plan.price.montly}</span>
                                        <span className="period">{view.payment_note}</span>
                                        <button type="button" className="btn btn-lg btn-toggle" data-toggle="button" aria-pressed="false" autoComplete="off" aria-label="Toggle montly or annual">
                                            <div className="handle"></div>
                                        </button>
                                    </div>
                                    <ul className="list">
                                      {plan.items.map((item, i) => {
                                        return (
                                          <li className="active" key={`pricing-list-${i}`}>{item}</li>
                                        )
                                      })}
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <Link to={view.cta_link} className="main-button text-uppercase">{view.cta}</Link>
                                </div>
                            </div>
                        </div>
                      )
                    })}
              </div>
          </div>
      </section>
  )
}

export default Pricing