import * as React from "react"
import { Link } from "gatsby"

// markup
const Welcome = (props) => {
  const view = props.view
  return (
    <div className="welcome-area" id="welcome">
        <div className="header-text">
            <div className="container">
                <div className="row">
                    <div className="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 col-sm-12">
                        <h1>{view.title}</h1>
                        <p>{view.description}</p>
                        <Link to ="/signup" className="main-button-slider">{view.more_link}</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Welcome
