import * as React from "react"

const Value = (props) => {
  return (
    <>
      {props.view.items.map((item, index) => {
        return (
          <section className="section padding-top-70 padding-bottom-0" id="features" key={`value-${index}`}>
              <div className={ item.class ? `"container ${item.class}"`: "container"}>
                  <CheckMod index={index+1} item={item}/>
              </div>
          </section>
        )
      })}
    </>
  )
}

const CheckMod = (props) => {
  const {index, item} = props
  if (index % 2 === 0) {
    return <Even item={item}/>
  }
  return <Odd item={item}/>
}

const Even = (props) => {
  const item = props.item
  return (
    <div className="row">
        <div className="col-lg-5 col-md-12 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
            <img src={item.image} className="rounded img-fluid d-block mx-auto" alt="App"/>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-6 col-md-12 col-sm-12 align-self-center mobile-top-fix">
            <div className="left-heading">
                <h2 className="section-title">{item.title}</h2>
            </div>
            <div className="left-text">
                <p>{item.description}</p>
            </div>
        </div>
    </div>
  )
}

const Odd = (props) => {
  const item = props.item
  return (
    <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 align-self-center mobile-bottom-fix">
            <div className="left-heading">
                <h2 className="section-title">{item.title}</h2>
            </div>
            <div className="left-text">
                <p>{item.description}</p>
            </div>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-bottom-fix-big" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
            <img src={item.image} className="rounded img-fluid d-block mx-auto" alt="App"/>
        </div>
    </div>
  )
}

export default Value