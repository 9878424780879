import * as React from "react"

const Contact = (props) => {
  const view = props.view
  return (
    <section className="section colored" id="contact-us">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="center-heading">
                    <h2 className="section-title">{view.title}</h2>
                </div>
            </div>
            <div className="offset-lg-3 col-lg-6">
                <div className="center-text">
                    <p>{view.description}</p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <img src={view.image} className="rounded d-block mx-auto" alt="Contact"/>
            </div>

              <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="contact-form">
                  <form id="contact" action="https://formspree.io/f/mdoprrld" method="post">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required=""/>
                        </fieldset>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input name="contact-email" type="email" className="form-control" id="contact-email" placeholder="E-Mail Address" required=""/>
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <textarea name="message" rows="6" className="form-control" id="message" placeholder="Your Message" required=""></textarea>
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <button type="submit" id="contact-form-submit" className="main-button right" aria-label="Send Feedback">{view.submit}</button>
                          <span id="contact-success" className="d-none badge rounded-pill border text-success mr-3 py-3 px-4 text-white right">{view.success}</span>
                          <span id="contact-danger" className="d-none badge rounded-pill border text-danger mr-3 py-3 px-4 text-white right">{view.error}</span>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Contact