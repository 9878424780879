import * as React from "react"

const Process = (props) => {
  const view = props.view
  if (!view.items) {
    return ""
  }

  return (
    <section className="mini mt-5" id="work-process">
      <div className="mini-content">
          <div className="container">
              <div className="row">
                  <div className="offset-lg-3 col-lg-6">
                      <div className="info">
                          <h1>{view.title}</h1>
                          <p>{view.description}</p>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  {view.items.map((item, i) => {
                    return (
                        <div className="my-2 col" key={`process-${i}`}>
                            <p className="mini-box h-100">
                                <i className={`${item.icon} default-color`}></i>
                                <strong>{item.title}</strong>
                                <span>{item.description}</span>
                            </p>
                        </div>
                    )
                  })}
              </div>
          </div>
      </div>
    </section>
  )
}

export default Process