import * as React from "react"

const Testimonials = (props) => {
  const view = props.view
  if (!view.items) {
    return ""
  }

  return (
    <section className="section" id="testimonials">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="center-heading">
                        <h2 className="section-title">{view.title}</h2>
                    </div>
                </div>
                <div className="offset-lg-3 col-lg-6">
                    <div className="center-text">
                        <p>{view.description}</p>
                    </div>
                </div>
            </div>

            <div className="row">
                {view.items.map((item, i) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12" key={`testimonials-${i}`}>
                        <div className="team-item">
                            <div className="team-content">
                                <i><img src="/assets/images/testimonial-icon.png" alt=""/></i>
                                <p>Proin a neque nisi. Nam ipsum nisi, venenatis ut nulla quis, egestas scelerisque orci. Maecenas a finibus odio.</p>
                                <div className="user-image">
                                    <img src="http://placehold.it/60x60" alt=""/>
                                </div>
                                <div className="team-info">
                                    <h3 className="user-name">Catherine Soft</h3>
                                    <span>Managing Director</span>
                                </div>
                            </div>
                        </div>
                    </div>
              
                    )
                  })}
            </div>
        </div>
    </section>
  )
}

export default Testimonials