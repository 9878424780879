import React, { useEffect } from 'react';
import DefaultLayout from "../../components/layouts/default"
import Welcome from "../../components/home/welcome"
import BigCards from "../../components/home/big-cards"
import Value from "../../components/home/value"
import Process from "../../components/home/process"
import Testimonials from "../../components/home/testimonials"
import Pricing from "../../components/home/pricing"
import Contact from "../../components/home/contact"
import JSONData from "../../data/texts.json"
import agent from "../../lib/agent"

// markup
const Home = () => {
  useEffect(() => {
    agent.Account.auth()
      .then(res => {
        window.location.href = "/app"
      })
      .catch(err => {
        window.localStorage.removeItem("jwt");
      })
  }, []);
  
  const main = JSONData.routes.main

  return (
    <main>
      <title>Home Page</title>
      <DefaultLayout menu={JSONData.menu}>
        <Welcome view={main.header}/>
        <BigCards cards={main.big_cards}/>
        <Value view={main.value}/>
        <Process view={main.process}/>
        <Testimonials view={main.testimonials}/>
        <Pricing view={main.pricing}/>
        <Contact view={main.contact}/>
      </DefaultLayout>
    </main>
  )
}

export default Home
