import React from "react"
import Header from "../general/header"
import Footer from "../general/footer"

export default function DefaultLayout({ children, menu }) {
  return (
    <>
      <Header menu={menu}/>
      {children}
      <Footer/>
    </>
  )
}